<template>
  <div class="pb-5">
    <h4 class="mb-4">Create your account</h4>

    <Message
      class="mb-5"
      description="
        An account activation confirmation code was
        sent to your email address. Please check your mail
      "
    />

    <div class="d-flex align-items-center flex-wrap">
      <router-link :to="{ name: 'First' }">
        <button class="btn-back">Back to main page</button>
      </router-link>

      <router-link :to="{ name: 'SignIn' }" class="text-decoration-none m-3 mr-0">Log in page</router-link>
    </div>
  </div>
</template>

<script>
import Message from '@/components/Message'

export default {
  components: {
    Message
  }
}
</script>
