<template>
  <div class="message">
    <img :src="require(`@/assets/icons/${type}Icon.svg`)" :alt="type" class="mb-3">
    <h4 class="mb-3">{{ text || type === 'success' ? 'Successfully!' : 'Something went wrong!' }}</h4>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'success' // 'success', 'error'
    },
    text: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  background-color: $grey;
  border-radius: 5px;
  padding: 30px 40px 70px;
}

@media (max-width: 992px) {
  .message img {
    width: 60px;
    height: 60px;
  }
}
</style>
